import { useEffect } from 'react';
import { useState } from 'react';
import './App.css';
import Form from './Form/Form';

function App() {

    const [selected, setSelected] = useState("Waste")
    const [general, setGeneral] = useState(null)
    const [users, setUsers] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const [info, setInfo] = useState({ infoMessage: "Undetermined", infoType: "undetermined" })
    const [submitted, setSubmitted] = useState(false)

    const [job, setJob] = useState({
        _client_id: "",
        _contact: "",
        _order_number: "",
        _part_id: "",
    })

    const [waste, setWaste] = useState({
        _client_id: "",
        _contact: "",
        _ammount_bumper: "",
        _ammount_headlight: ""
    })

    useEffect(() => { if (general !== null && users !== null) setLoaded(true) }, [general, users])
    useEffect(() => {
        fetch("https://api.pttapal.com/api/general/get_general_data").then(res => res.json()).then(res => setGeneral(res))
        fetch("https://api.pttapal.com/api/user/get_users").then(res => res.json()).then(res => setUsers(res))
    }, [])


    return (
        <div className="container">
            <div className="details">
                <span>
                    <img src="/pal_logo.png" />
                </span>
                <span>
                    <h1><center>PICKUP-REQUEST</center></h1>
                    <span className="buttons">
                        <button className={selected === "Waste" ? "selected" : ""} onClick={e => setSelected("Waste")}>WASTE</button>
                        <button className={selected === "Job" ? "selected" : ""} onClick={e => setSelected("Job")}>JOB</button>
                    </span>
                </span>
                <span>
                </span>
            </div>
            <div>
                {loaded === false ? <h3 className="loading">Loading... Please wait.</h3> : <Form data={
                    {
                        handleChange: e => {
                            setSubmitted(false)
                            setInfo({ infoMessage: "Undetermined", infoType: "undetermined" })
                            if (selected === "Waste") setWaste({ ...waste, [e.target.name]: e.target.value })
                            else setJob({ ...job, [e.target.name]: e.target.value })
                        },
                        handleSubmit: e => {
                            e.preventDefault()
                            setSubmitted(true)
                            if (selected === "Job" && ["_client_id", "_order_number", "_part_id"].includes("")) return setInfo({ infoType: "warning", infoMessage: "Please fill in the form." })
                            if (selected === "Waste" && ["_client_id", "_ammount_bumper", "_ammount_headlight"].includes("")) return setInfo({ infoType: "warning", infoMessage: "Please fill in the form." })

                            if (selected === "Job") {
                                const data = new FormData()
                                data.append("_client_id", job._client_id)
                                data.append("_contact", job._contact)
                                data.append("_order_number", job._order_number)
                                data.append("_part_id", job._part_id)
                                fetch("https://api.pttapal.com//api/mailing/pickup_job", {
                                    method: "POST",
                                    body: data
                                })
                                    .then(res => res.status === 200 ? setInfo({ infoType: "success", infoMessage: "Job order placed successfully." }) : setInfo({ infoMessage: "error", infoType: "Error! Please contact support@pttapal.com." }))
                            } else {
                                const data = new FormData()
                                data.append("_client_id", waste._client_id)
                                data.append("_ammount_bumper", waste._ammount_bumper)
                                data.append("_ammount_headlight", waste._ammount_headlight)
                                data.append("_contact", waste._contact)
                                fetch("https://api.pttapal.com//api/mailing/pickup_waste", {
                                    method: "POST",
                                    body: data
                                })
                                    .then(res => res.status === 200 ? setInfo({ infoType: "success", infoMessage: "Job order placed successfully." }) : setInfo({ infoMessage: "error", infoType: "Error! Please contact support@pttapal.com." }))
                            }
                        },
                        fields: selected === "Waste" ? [
                            {
                                name: "_client_id",
                                label: "Select Shop",
                                value: waste._client_id,
                                formType: "select",
                                options: users.allUsers.filter(user => user._archived === 0 && user._type_id === 2),
                                display: "_username",
                                identifier: "_username"
                            },
                            {
                                name: "_contact",
                                label: "Contact Name",
                                type: "text",
                                value: waste._contact,
                                placeholder: "Enter Contact Name",
                                formType: "input",
                            },
                            {
                                name: "_ammount_bumper",
                                label: "Number of bumpers",
                                type: "number",
                                value: waste._ammount_bumper,
                                placeholder: "Enter Number of bumpers",
                                formType: "input",
                            },
                            {
                                name: "_ammount_headlight",
                                label: "Number of headlights",
                                type: "number",
                                value: waste._ammount_headlight,
                                placeholder: "Enter Number of headlights",
                                formType: "input",
                            },
                        ] : [
                            {
                                name: "_client_id",
                                label: "Select Shop",
                                value: job._client_id,
                                formType: "select",
                                options: users.allUsers.filter(user => user._archived === 0 && user._type_id === 2),
                                display: "_username",
                                identifier: "_username"
                            },
                            {
                                name: "_contact",
                                label: "Contact Name",
                                type: "text",
                                value: job._contact,
                                placeholder: "Enter Contact Name",
                                formType: "input",
                            },
                            {
                                name: "_order_number",
                                label: "RO.",
                                type: "text",
                                value: job._order_number,
                                placeholder: "Enter RO Number",
                                formType: "input",
                            },
                            {
                                name: "_part_id",
                                label: "Select Part",
                                value: job._part_id,
                                formType: "select",
                                options: general._parts.filter(p => [1, 6, 7].includes(p._id) === false ),
                                display: "_name",
                                identifier: "_name"
                            }
                        ],
                        fieldsData: selected === "Waste" ? waste : job,
                        submitButtonText: selected === "Waste" ? "Request Waste Pickup" : "Request Job",
                        formName: selected === "Waste" ? "Form for requesting waste pickup" : "Form for requesting job orders",
                        submitted,
                        info,
                    }
                } />}
            </div>
        </div>
    );
}

export default App;
